import styled, { css } from 'styled-components';
import ReactCodeInput from 'react-code-input';

export interface StyledCodeInputProps {}

export const StyledReactCodeInput = styled(ReactCodeInput)<{
  withBorder?: boolean;
}>`
  ${({ withBorder }) =>
    withBorder
      ? css`
          input[value='']:not(:focus) {
            border: 2px solid rgb(237, 237, 237) !important;
            border-bottom-color: #84888d !important;
          }
        `
      : css`
          input[value='']:not(:focus) {
            border: 2px solid transparent !important;
            border-bottom-color: #84888d !important;
          }
        `}
`;
